<template>
  <div>
    <div id="associatedegree-grid-container">
      <b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.associatedegreeadd-modal
          class="d-flex align-items-left"
          squared
          variant="outline-primary"
        >
          <feather-icon
            icon="AddIcon"
            class="mr-50"
          />
          <span>Ön Lisans Bölümü Ekle</span>
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Ön Lisans Bölümü ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Ön Lisans Bölümü kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-25"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  class="mr-1"
                  variant="outline-warning"
                  @click="onUpdateAssociateDegreeBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteAssociateDegreeBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Ön Lisans EKLE MODAL -->
    <b-modal
      id="associatedegreeadd-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Ön Lisans Bölümü Ekle"
      @ok="onModalSaveAssociateDegree"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="associateDegreeName">Ön Lisans Bölüm Adı:</label>
          <b-form-input
            id="associateDegreeName"
            v-model="newAssociateDegreeName"
            type="text"
            placeholder="Ön Lisans Bölüm Adı"
            :state="newAssociateDegreeName.length > 0"
          />
          <b-form-invalid-feedback>
            Ön Lisans adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Ön Lisans Güncelle  MODAL -->
    <b-modal
      id="onlisansguncelle-modal"
      cancel-variant="outline-secondary"
      ok-title="Güncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Ön Lisans Bölüm Güncelle"
      @ok="onModalUpdateAssociateDegree"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group hidden>
          <label for="associateDegreeId">Identity</label>
          <b-form-input
            id="associateDegreeId"
            v-model="updAssociateDegreeId"
            disabled
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="associateDegreeName">Ön Lisans Bölüm Adı:</label>
          <b-form-input
            id="associateDegreeName"
            v-model="updAssociateDegreeName"
            type="text"
            placeholder="Ön Lisans Bölüm Adı"
            :state="updAssociateDegreeName.length > 0"
          />
          <b-form-invalid-feedback>
            Ön Lisans adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import * as apiOnlisans from '@/api/tanimlar/AssociateDegree'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButtonGroup, BButton, BModal, BForm, VBModal, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AssociateDegreeList',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButtonGroup,
    BButton,
    BModal,
    BForm,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newAssociateDegreeId: -1,
      newAssociateDegreeName: '',
      updAssociateDegreeId: 0,
      updAssociateDegreeName: '',
      pageLength: 15,
      columns: [
        {
          label: 'Ön Lisans Bölüm Adı',
          field: 'associateDegreeName',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  created() {
    apiOnlisans.getAssociateDegree().then(res => {
      this.rows = res.data.list
    })
  },
  mounted() {
    this.getAssociateDegreeList()
  },
  methods: {
    getAssociateDegreeList() {
      apiOnlisans.getAssociateDegree().then(res => {
        this.rows = res.data.list
      })
    },
    clearModal() {
      this.newAssociateDegreeName = ''
      this.updAssociateDegreeName = ''
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveAssociateDegree() {
      if (this.newAssociateDegreeName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklenemedi',
            text: 'Boş kayıt eklenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const associatedegreeItem = {
        // associateDegreeId: 0,
        associateDegreeName: this.newAssociateDegreeName,
      }
      apiOnlisans.addAssociateDegree(associatedegreeItem).then(() => {
        this.getAssociateDegreeList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ön Lisans Bölümü Ekle',
          text: 'Yeni kayıt eklendi',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
      this.clearModal()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateAssociateDegreeBtnClicked(row) {
      this.updAssociateDegreeId = row.associateDegreeId
      this.updAssociateDegreeName = row.associateDegreeName
      this.$bvModal.show('onlisansguncelle-modal')
    },
    onDeleteAssociateDegreeBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.associateDegreeName}' tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiOnlisans.deleteAssociateDegree(row.associateDegreeId).then(() => {
              this.getAssociateDegreeList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.associateDegreeName} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onModalUpdateAssociateDegree() {
      if (this.updAssociateDegreeName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Güncellenemedi',
            text: 'Boş kayıt güncellenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const associatedegreeItem = {
        associateDegreeId: this.updAssociateDegreeId,
        associateDegreeName: this.updAssociateDegreeName,
      }
      apiOnlisans.updateAssociateDegree(associatedegreeItem).then(() => {
        this.getAssociateDegreeList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kayıt Güncellendi',
          text: 'Kayıt başarıyla güncellendi',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
      this.clearModal()
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
