import request from '@/utils/request'

export function getAssociateDegreeById(data) {
  return request({
    url: `/api/AssociateDegree/GetAssociateDegreeById/${data}`,
    method: 'get',
  })
}

export function getAssociateDegree() {
  return request({
    url: '/api/AssociateDegree/GetAssociateDegree',
    method: 'get',
  })
}

export function addAssociateDegree(data) {
  return request({
    url: '/api/AssociateDegree/AddAssociateDegree',
    method: 'post',
    data,
  })
}

export function updateAssociateDegree(data) {
  return request({
    url: '/api/AssociateDegree/UpdateAssociateDegree',
    method: 'put',
    data,
  })
}
export function deleteAssociateDegree(data) {
  return request({
    url: `/api/AssociateDegree/DeleteAssociateDegree?associateDegreeId=${data}`,
    method: 'delete',
  })
}
